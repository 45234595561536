import React from 'react';
import styles from './styles/Notification.module.css';

const Notification = ({ notifications }) => {
  return (
    <div className={styles.notificationContainer}>
      {notifications.map(({ id, type, message }) => (
        <div
          key={id}
          className={`${styles.notification} ${
            type === 'success'
              ? styles.success
              : type === 'error'
              ? styles.error
              : styles.info
          }`}
        >
          {message}
        </div>
      ))}
    </div>
  );
};

export default Notification;