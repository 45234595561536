import React, { useState, useEffect } from "react";
import styles from "./styles/Inputs.module.css";
import Notification from './Notification';
import { fetchAllResults } from '../utils/apiService';

const Inputs = ({
  onDataFetched,
  inputsState,
  onInputsStateChange,
  onFilterUpdate,
}) => {
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const showNotification = (type = "info", message) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, message }]);
  
    // Auto-remove after 3 seconds
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    }, 3000);
  };

  // Seniorities options
  const seniorityOptions = [
    "owner",
    "c_level",
    "partner",
    "vp",
    "director",
    "head",
    "manager",
    "senior",
  ];

  // Initialize state for seniorities checkboxes
  useEffect(() => {
    if (!inputsState.seniorities) {
      console.log("Initializing seniorities state...");
      onInputsStateChange((prevState) => ({
        ...prevState,
        seniorities: [], // Default to none selected
      }));
    }
  }, [inputsState.seniorities, onInputsStateChange]);

  const validateInputs = () => {
    const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    const domains = inputsState.domains.split("\n").filter(Boolean);
  
    const invalidDomains = domains.filter(domain => !domainRegex.test(domain));
  
    if (invalidDomains.length > 0) {
      showNotification('error', `Invalid domains entered: ${invalidDomains.join(', ')}`);
      return false;
    }
  
    if (!inputsState.includeTitles.trim()) {
      showNotification('error', '"Job Titles" field is required.');
      return false;
    }
  
    return true;
  };

  const handleCheckboxChange = (option) => {
    const updatedSeniorities = inputsState.seniorities.includes(option)
      ? inputsState.seniorities.filter((item) => item !== option) // Remove option if unchecked
      : [...inputsState.seniorities, option]; // Add option if checked

    console.log("Updated seniorities:", updatedSeniorities);
    onInputsStateChange({ ...inputsState, seniorities: updatedSeniorities });
  };

  const handleSearch = async () => {
    if (!validateInputs()) {
      return;
    }
  
    const formattedDomains = inputsState.domains
      .split("\n")
      .filter(Boolean)
      .join(",");
    const formattedTitles = inputsState.includeTitles
      .split("\n")
      .filter(Boolean) // Remove empty lines
      .map((title) => {
        const trimmedTitle = title.trim();
        // If the title is already quoted, return it as-is
        if (trimmedTitle.startsWith('"') && trimmedTitle.endsWith('"')) {
          return trimmedTitle; // Exact match
        }
        // Otherwise, return the title as a partial match (no extra quotes added)
        return trimmedTitle;
      });
  
    try {
      setLoading(true); // Add a loading state
      showNotification('info', 'Fetching data, please wait...');
  
      const combinedData = await fetchAllResults({
        q_organization_domains: formattedDomains,
        person_titles: formattedTitles,
        person_seniorities: inputsState.seniorities,
        per_page: 100, // Default or desired fixed value
      });
  
      if (combinedData.length > 0) {
        showNotification('success', `${combinedData.length} rows fetched successfully!`);
        if (onDataFetched) {
          onDataFetched(combinedData);
        }
      } else {
        showNotification('info', 'No data found for the given inputs.');
      }
    } catch (error) {
      console.error("Error fetching paginated results:", error);
      showNotification('error', 'Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const isSearchDisabled =
    !inputsState.domains.trim() || !inputsState.includeTitles.trim();

  return (
    <div>
    <Notification notifications={notifications} />
    <div className={styles.container}>
      <h1 className={styles.title}>
        <img
          src={require("../assets/apollo-logo.png")}
          alt="Apollo Logo"
          className={styles.logo}
        />
        Search People with Apollo API
      </h1>
      <div className={styles.apolloBlock}>
        <div className={styles.inputGroup}>
          <label htmlFor="domains" className={styles.smallLabelAbove}>
            Company Domains
          </label>
          <textarea
            id="domains"
            placeholder="Enter company domains (one per line)"
            value={inputsState.domains || ""}
            onChange={(e) =>
              onInputsStateChange({ domains: e.target.value })
            }
            className={styles.textArea}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="includeTitles" className={styles.smallLabelAbove}>
            Job Titles
          </label>
          <textarea
            id="includeTitles"
            placeholder={`Include job titles (one per line)\n+ Use double quotes for Exact Match search`}
            value={inputsState.includeTitles || ""}
            onChange={(e) =>
              onInputsStateChange({ includeTitles: e.target.value })
            }
            className={styles.textArea}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.smallLabelAbove}>
            Person Seniority (Leave Empty to Include All)
          </label>
          <div className={styles.checkboxGroup}>
            {seniorityOptions.map((option) => (
              <label key={option} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={
                    inputsState.seniorities?.includes(option) || false
                  }
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      </div>
      <button
  onClick={handleSearch}
  className={`${styles.searchButton} ${isSearchDisabled || loading ? styles.disabledButton : ""}`}
  disabled={isSearchDisabled || loading} // Disable button while loading
>
  {loading ? "Loading..." : "Search People"}
</button>
     {/* No need for inline error messages anymore as notifications are being used */}
      <div className={styles.filterBlock}>
        <div
          className={styles.filterHeader}
          onClick={(e) => toggleFiltersVisibility()}
        >
          <span>Post-Search Filtering</span>
          <span className={styles.arrow}>
            {filtersVisible ? "▲" : "▼"}
          </span>
        </div>
        {filtersVisible && (
          <div className={styles.filterContent}>
            <div className={styles.centeredContent}>
              <label
                htmlFor="excludeTitles"
                className={styles.smallLabelAbove}
              >
                Job Titles Exclusions
              </label>
              <textarea
                id="excludeTitles"
                placeholder="Exclude job titles (one per line)"
                value={inputsState.excludeTitles || ""}
                onChange={(e) =>
                  onInputsStateChange({ excludeTitles: e.target.value })
                }
                className={styles.filterTextArea}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  localStorage.setItem(
                    "excludeTitles",
                    inputsState.excludeTitles
                  );
                  showNotification('success', 'Filter saved successfully!');
                  if (onFilterUpdate) {
                    onFilterUpdate();
                  }
                }}
                className={styles.saveButton}
              >
                Save Filter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Inputs;