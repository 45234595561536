import React, { useState, useEffect } from 'react'; // Added useEffect
import Pusher from 'pusher-js'; // Added Pusher
import fullEnrichLogo from '../assets/fullenrich.svg';
import runFunctionIcon from '../assets/run-function.svg';
import apolloIcon from '../assets/apollo-favicon.ico';
import styles from './styles/Outputs.module.css';
import { enrichContacts } from '../utils/apiService'; // Importing enrichContacts function
import Notification from './Notification';

const Outputs = ({ data = [], excludeTitles = '', filterMode = 'all' }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(data);
  }, [data]);
  const [filter, setFilter] = useState('relevant'); // Default to "relevant"
  const [columns] = useState([
    'Checkbox', // Checkbox column explicitly defined
    'Company',
    'Domain',
    'First Name',
    'Last Name',
    'Job Title',
    'LinkedIn',
    'Seniority',
    'FullEnrich',
  ]);
  const [selectedRows, setSelectedRows] = useState([]); // Manage selected rows
  const [selectAll, setSelectAll] = useState(false); // Manage header checkbox state
  const [showPopup, setShowPopup] = useState(false); // Control the popup visibility
  const [mostProbableEmails, setMostProbableEmails] = useState([]); // Track enriched emails
  const [isCopied, setIsCopied] = useState(false);
  const [inProgressRows, setInProgressRows] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [columnWidths, setColumnWidths] = useState(columns.map(() => 150)); // Default width for all columns

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
  
    // Subscribe to the 'enrichment' channel
    const channel = pusher.subscribe('enrichment');
  
    channel.bind("completed", (data) => {
      console.log("Pusher event received:", data);
  
      if (data.enrichedContacts && Array.isArray(data.enrichedContacts)) {
        setTableData((prevData) =>
          prevData.map((row) => {
            const enrichedContact = data.enrichedContacts.find(
              (contact) =>
                contact.firstname === row.firstName &&
                contact.lastname === row.lastName &&
                contact.domain === row.domain
            );
  
            return enrichedContact
              ? { ...row, fullEnrich: enrichedContact.most_probable_email }
              : row;
          })
        );
  
        // Remove rows from inProgressRows
        setInProgressRows((prev) =>
          prev.filter(
            (rowIdentifier) =>
              !data.enrichedContacts.some(
                (contact) =>
                  rowIdentifier ===
                  `${contact.firstname}-${contact.lastname}-${contact.domain}`
              )
          )
        );
  
        showNotification('success', `Enrichment complete. Updated ${data.enrichedContacts.length} rows.`);
      } else {
        console.warn("Invalid data received from Pusher:", data);
      }
    });
  
    // Cleanup on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []); // No dependencies

  const showNotification = (type = "info", message) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, message }]);
  
    // Auto-remove after 3 seconds
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    }, 3000);
  };

  const handleSelectAll = () => {
    const newSelectAllState = !selectAll; // Toggle the "select all" state
    setSelectAll(newSelectAllState);
  
    if (newSelectAllState) {
      // Select all rows currently visible in the filtered view
      const allVisibleIdentifiers = filteredData.map(
        (row) => `${row.firstName}-${row.lastName}-${row.domain}`
      );
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, ...allVisibleIdentifiers]);
    } else {
      // Deselect only the rows in the current filtered view
      const visibleIdentifiers = filteredData.map(
        (row) => `${row.firstName}-${row.lastName}-${row.domain}`
      );
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => !visibleIdentifiers.includes(id))
      );
    }
  };

  const handleRowSelection = (row) => {
    const rowIdentifier = `${row.firstName}-${row.lastName}-${row.domain}`;
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIdentifier)
        ? prevSelectedRows.filter((id) => id !== rowIdentifier) // Deselect if selected
        : [...prevSelectedRows, rowIdentifier] // Add if not selected
    );
  };
  
  const handleCopySelected = () => {
    // Filter selected rows to include only those in the current filtered view
    const selectedData = filteredData.filter((row) =>
      selectedRows.includes(`${row.firstName}-${row.lastName}-${row.domain}`)
    ).map((row) => ({
      Company: row.company || "N/A",
      Domain: row.domain || "N/A",
      "First Name": row.firstName || "N/A",
      "Last Name": row.lastName || "N/A",
      "Job Title": row.title || "N/A",
      LinkedIn: row.linkedin || "N/A",
      Seniority: row.seniority || "N/A",
      FullEnrich: row.fullEnrich || "Pending Enrichment",
    }));
  
    if (selectedData.length === 0) {
      // Show notification if no rows were selected
      showNotification('error', 'No rows selected to copy.');
      return;
    }
  
    const clipboardContent = [
      Object.keys(selectedData[0]).join("\t"),
      ...selectedData.map((row) => Object.values(row).join("\t")),
    ].join("\n");
  
    navigator.clipboard.writeText(clipboardContent)
      .then(() => {
        // Show success notification
        showNotification('success', `${selectedData.length} rows copied to clipboard.`);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset copy state after 2 seconds
      })
      .catch((err) => {
        // Show error notification if copying fails
        showNotification('error', 'Failed to copy rows. Please try again.');
        console.error("Failed to copy: ", err);
      });
  };

  const excludeKeywords = excludeTitles.split('\n').filter(Boolean);

  const filteredData = tableData
    .filter((item) => {
      const isIrrelevant = excludeKeywords.some((keyword) => {
        const regex = new RegExp(`\\b${keyword.toLowerCase().trim()}\\b`, 'i');
        return regex.test(item.title?.toLowerCase() || '');
      });

      if (filter === 'relevant') return !isIrrelevant;
      if (filter === 'irrelevant') return isIrrelevant;
      return true; // Show all for "all"
    })
    .sort((a, b) => {
      const companyA = a.company?.toLowerCase() || '';
      const companyB = b.company?.toLowerCase() || '';
      if (companyA < companyB) return -1;
      if (companyA > companyB) return 1;

      const linkedinA = a.linkedin?.toLowerCase() || '';
      const linkedinB = b.linkedin?.toLowerCase() || '';
      if (linkedinA < linkedinB) return -1;
      if (linkedinA > linkedinB) return 1;

      return 0;
    });

    const handleConfirmEnrichment = async () => {
      const selectedContacts = tableData.filter((row) =>
        selectedRows.includes(`${row.firstName}-${row.lastName}-${row.domain}`)
      );
    
      if (selectedContacts.length === 0) {
        showNotification('error', 'No valid rows to enrich. Please check your selection.');
        return;
      }
    
      console.log('Selected Rows for Enrichment:', selectedContacts);
      console.log('Payload for FullEnrich:', JSON.stringify(selectedContacts, null, 2));
    
      setMostProbableEmails([]);
      setInProgressRows((prev) => [...prev, ...selectedRows]);
    
      try {
        showNotification('info', 'Enrichment process initiated. Please wait...');
        const enrichmentResult = await enrichContacts(selectedContacts);
        console.log('Enrichment Result:', enrichmentResult);
    
        // Validate enrichmentResult to check for enrichment_id
        if (enrichmentResult && enrichmentResult.enrichment_id) {
          showNotification('success', 'Enrichment request sent. Waiting for results...');
        } else {
          showNotification('error', 'Failed to retrieve enrichment ID.');
        }
      } catch (error) {
        console.error('Error enriching contacts:', error);
        showNotification('error', 'Failed to enrich emails. Please try again later.');
      } finally {
        setShowPopup(false);
      }
    };

  const renderEmptyRows = () => {
    const rows = [];
    for (let i = 0; i < 10; i++) {
      rows.push(
        <tr key={`empty-row-${i}`} className={styles.emptyRow}>
          {columns.map((col, index) => (
            <td key={`empty-cell-${i}-${index}`} className={styles.emptyCell}></td>
          ))}
        </tr>
      );
    }
    return rows;
  };

  const updateColumnWidth = (columnIndex, newWidth) => {
    setColumnWidths((prevWidths) => {
      const updatedWidths = [...prevWidths];
      updatedWidths[columnIndex] = Math.max(newWidth, 50); // Minimum width of 50px
      return updatedWidths;
    });
  };

  // Adjust the startResize function to account for the cursor position
  const startResize = (index, e) => {
    e.preventDefault();
    const startX = e.clientX; // Get the starting X position of the cursor
    const startWidth = columnWidths[index]; // Current width of the column
  
    const handleMouseMove = (moveEvent) => {
        const deltaX = moveEvent.clientX - startX; // Calculate horizontal movement
        const newWidth = Math.max(startWidth + deltaX, 50); // Prevent width from going below 50px
        updateColumnWidth(index, newWidth); // Call updateColumnWidth to update state
    };
  
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
  
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={styles.outputsContainer}>
    {/* Notifications */}
    <Notification notifications={notifications} />

    {/* Actions Container */}
    <div className={styles.actionsContainer}>
      {/* Left-aligned button */}
      <div className={styles.leftActions}>
        <button
          className={styles.findEmailsButton}
          onClick={() => setShowPopup(true)}
          disabled={selectedRows.length === 0}
        >
          Find Emails
        </button>
      </div>

      {/* Right-aligned button */}
      <div className={styles.rightActions}>
        <button
          className={`${styles.copyButton} ${isCopied ? styles.copied : ''}`}
          onClick={handleCopySelected}
          disabled={selectedRows.length === 0}
        >
          Copy Selected
        </button>
      </div>

  {/* Success and error messages */}
</div>
<div className={styles.filterSwitcher}>
  <label>
    <input
      type="radio"
      name="filterMode"
      value="relevant"
      checked={filter === 'relevant'}
      onChange={() => setFilter('relevant')}
    />
    Only show relevant people ({tableData.filter((item) => {
      const isIrrelevant = excludeKeywords.some((keyword) => {
        const regex = new RegExp(`\\b${keyword.toLowerCase().trim()}\\b`, 'i');
        return regex.test(item.title?.toLowerCase() || '');
      });
      return !isIrrelevant; // Count relevant rows
    }).length})
  </label>
  <label>
    <input
      type="radio"
      name="filterMode"
      value="irrelevant"
      checked={filter === 'irrelevant'}
      onChange={() => setFilter('irrelevant')}
    />
    Only show irrelevant people ({tableData.filter((item) => {
      const isIrrelevant = excludeKeywords.some((keyword) => {
        const regex = new RegExp(`\\b${keyword.toLowerCase().trim()}\\b`, 'i');
        return regex.test(item.title?.toLowerCase() || '');
      });
      return isIrrelevant; // Count irrelevant rows
    }).length})
  </label>
  <label>
    <input
      type="radio"
      name="filterMode"
      value="all"
      checked={filter === 'all'}
      onChange={() => setFilter('all')}
    />
    Show all results ({tableData.length})
  </label>
</div>

      <div className={styles.tableContainer}>
  {/* Show the selected rows count if any rows are selected */}
  <div
  className={styles.selectedRowsMessage}
  style={{ visibility: selectedRows.length > 0 ? 'visible' : 'hidden' }}
>
  {selectedRows.length > 0
    ? `${selectedRows.length} row${selectedRows.length > 1 ? 's' : ''} selected`
    : ''}
</div>
  <table className={styles.outputsTable}>
    <thead>
      <tr>
        {columns.map((col, index) => (
          <th
            key={index}
            className={`${styles.headerCell} ${
              col === 'FullEnrich' ? styles.fullenrichHeader : styles.apolloHeader
            }`}
            style={{ width: columnWidths[index] }} // Dynamically set column width
          >
            {col === 'Checkbox' ? (
              <div
                className={styles.checkboxWrapper}
                onClick={handleSelectAll}
              >
                <input
                  type="checkbox"
                  id="selectAll"
                  className={styles.selectAllCheckbox}
                  checked={selectAll}
                  onChange={handleSelectAll}
                  onClick={(e) => e.stopPropagation()} // Prevent row selection on checkbox click
                />
              </div>
            ) : col === 'Company' || col === 'Domain' || col === 'First Name' ||
              col === 'Last Name' || col === 'Job Title' || col === 'LinkedIn' ||
              col === 'Seniority' ? (
              <>
                <img
                  src={apolloIcon}
                  alt="Apollo"
                  className={styles.tableIcon}
                />
                {col}
              </>
            ) : col === 'FullEnrich' ? (
              <>
                <img
                  src={fullEnrichLogo}
                  alt="FullEnrich"
                  className={styles.tableIcon}
                />
                {col}
                <img
                  src={runFunctionIcon}
                  alt="Run Function"
                  className={styles.runFunctionIcon}
                />
              </>
            ) : (
              col
            )}
            {/* Resize Handle */}
            <div
              className={styles.resizeHandle}
              onMouseDown={(e) => startResize(index, e)}
            />
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <tr
            key={index}
            className={
              selectedRows.includes(`${item.firstName}-${item.lastName}-${item.domain}`)
                ? styles.highlightedRow
                : ''
            }
            onClick={() => handleRowSelection(item)}
          >
           <td
  className={styles.checkboxCell}
  onClick={(e) => {
    e.stopPropagation(); // Prevent row selection from cell clicks
    handleRowSelection(item); // Trigger row selection logic
  }}
>
  <div className={styles.checkboxWrapper}>
    <input
      type="checkbox"
      className={styles.rowCheckbox}
      checked={selectedRows.includes(`${item.firstName}-${item.lastName}-${item.domain}`)}
      readOnly // Prevent direct toggling; the logic is handled by the cell click
      onClick={(e) => e.stopPropagation()} // Prevent row click
    />
  </div>
</td>
            <td>
              <img
                src={item.logoUrl}
                alt={item.company || 'N/A'}
                className={styles.companyLogo}
              />
              {item.company || 'N/A'}
            </td>
            <td>
              {item.domain ? (
                <a
                  href={`https://${item.domain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.domainLink}
                >
                  {item.domain}
                </a>
              ) : (
                'N/A'
              )}
            </td>
            <td>{item.firstName || 'N/A'}</td>
            <td>{item.lastName || 'N/A'}</td>
            <td>{item.title || 'N/A'}</td>
            <td>
              {item.linkedin ? (
                <a
                  href={item.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkedinLink}
                >
                  {item.linkedin}
                </a>
              ) : (
                'N/A'
              )}
            </td>
            <td>{item.seniority || 'N/A'}</td>
            <td>
              {inProgressRows.includes(`${item.firstName}-${item.lastName}-${item.domain}`) ? (
                <div className={styles.loadingContainer}>
                  <span className={styles.loadingIcon}></span> In Progress
                </div>
              ) : (
                item.fullEnrich || 'Pending Enrichment'
              )}
            </td>
          </tr>
        ))
      ) : (
        renderEmptyRows()
      )}
    </tbody>
  </table>
</div>

{mostProbableEmails.length > 0 && (
  <div className={styles.enrichmentResults}>
    <h3>Enrichment Results:</h3>
    <table className={styles.resultsTable}>
      <thead>
        <tr>
          <th>Enrichment ID</th>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Domain</th>
        </tr>
      </thead>
      <tbody>
        {mostProbableEmails.map((result, index) => (
          <tr key={index}>
            <td>{result.enrichmentId || "N/A"}</td>
            <td>{result.email}</td>
            <td>{result.contact?.firstname || "N/A"}</td>
            <td>{result.contact?.lastname || "N/A"}</td>
            <td>{result.contact?.domain || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <p>Do you want to find emails for {selectedRows.length} contacts selected?</p>
            <div className={styles.popupActions}>
              <button
                onClick={handleConfirmEnrichment}
                className={styles.confirmButton}
              >
                Confirm
              </button>
              <button
                onClick={() => setShowPopup(false)}
                className={styles.cancelButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Outputs;