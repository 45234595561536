import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://api.8gtm.com"; // Base URL
const WEBHOOK_URL = `${API_URL}/webhook/fullenrich-complete`; // Full webhook URL
const FULLENRICH_API_KEY = process.env.FULLENRICH_API_KEY;

/**
 * Enrich contacts via the enrichment API in batches of 100.
 * @param {Array} contacts - Array of contacts to enrich.
 * @returns {Promise<void>} Resolves when all batches are sent.
 */
export const enrichContacts = async (contacts) => {
  const BATCH_SIZE = 100;
  const batches = [];

  for (let i = 0; i < contacts.length; i += BATCH_SIZE) {
    batches.push(contacts.slice(i, i + BATCH_SIZE));
  }

  console.log(`Total Batches: ${batches.length}`);

  for (const batch of batches) {
    const sanitizedBatch = batch.map((contact) => ({
      firstname: contact.firstName,
      lastname: contact.lastName,
      domain: contact.domain,
      linkedin_url: contact.linkedin,
      enrich_fields: ["contact.emails"],
    }));

    try {
      const ENRICH_CONTACTS_URL = `${API_URL}/enrich-contacts`;

      console.log("Payload being sent:", {
        name: "Email Enrichment",
        webhook_url: WEBHOOK_URL,
        datas: sanitizedBatch,
      });

      const response = await axios.post(
        ENRICH_CONTACTS_URL,
        {
          name: "Email Enrichment",
          webhook_url: WEBHOOK_URL,
          datas: sanitizedBatch,
        },
        {
          headers: {
            Authorization: `Bearer ${FULLENRICH_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Batch sent successfully:", response.data);

      // Return enrichment ID
      return response.data; // Ensure this contains enrichment_id
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || "Unknown error occurred";
      console.error("Error sending batch:", errorMessage);
      throw new Error("Failed to send batch for enrichment. Details: " + errorMessage);
    }
  }
};

/**
 * Fetch the enrichment result using the enrichment ID.
 * @param {string} enrichmentId - The ID of the enrichment process.
 * @returns {Promise<Object>} The API response containing enrichment data.
 */
export const fetchEnrichmentResult = async (enrichmentId) => {
  try {
    const FETCH_ENRICH_RESULT_URL = `${API_URL}/enrich-contacts/${enrichmentId}`;

    const response = await fetch(FETCH_ENRICH_RESULT_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed with status ${response.status}`);
    }

    const result = await response.json();

    if (!result || !result.most_probable_email) {
      throw new Error("Invalid enrichment result: Missing required fields");
    }

    return result;
  } catch (error) {
    console.error("Error fetching enrichment result:", error);
    throw error;
  }
};

/**
 * Fetch all paginated results from Apollo People Search.
 * @param {Object} requestData - The request payload for Apollo API.
 * @returns {Promise<Array>} Combined results from all pages.
 */
export const fetchAllResults = async (requestData) => {
  let currentPage = 1;
  let totalPages = 1;
  let allResults = [];

  try {
    do {
      console.log(`Fetching page ${currentPage}...`);

      const response = await axios.post(
        `${API_URL}/proxy/apollo-search`,
        { ...requestData, page: currentPage },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { contacts = [], people = [], pagination } = response.data;

      // Debug: Log the response of each page
      console.log(`Page ${currentPage} Response:`, response.data);

      // Add current page results
      allResults = [
        ...allResults,
        ...contacts.map((contact) => ({
          firstName: contact.first_name || "N/A",
          lastName: contact.last_name || "N/A",
          email: contact.email || "N/A",
          title: contact.title || "N/A",
          company:
            contact.organization_name || contact.account?.name || "N/A",
          domain:
            contact.account?.domain ||
            contact.organization?.primary_domain ||
            "N/A",
          linkedin: contact.linkedin_url || "N/A",
          seniority: contact.seniority || "N/A",
          logoUrl:
            contact.account?.logo_url || contact.organization?.logo_url || null,
        })),
        ...people.map((person) => ({
          firstName: person.first_name || "N/A",
          lastName: person.last_name || "N/A",
          email: person.email || "N/A",
          title: person.title || "N/A",
          company: person.organization?.name || person.account?.name || "N/A",
          domain:
            person.organization?.primary_domain ||
            person.account?.domain ||
            "N/A",
          linkedin: person.linkedin_url || "N/A",
          seniority: person.seniority || "N/A",
          logoUrl:
            person.account?.logo_url || person.organization?.logo_url || null,
        })),
      ];

      // Update pagination details
      totalPages = pagination.total_pages || 1;
      currentPage += 1;
    } while (currentPage <= totalPages);

    console.log(`Fetched ${allResults.length} results across ${totalPages} pages.`);
    return allResults;
  } catch (error) {
    console.error(
      "Error fetching all results:",
      error.response?.data || error.message
    );
    throw new Error("Failed to fetch all results.");
  }
};