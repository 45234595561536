import React, { useState } from 'react';
import Inputs from './components/Inputs';
import Outputs from './components/Outputs';
import styles from './styles/App.module.css';

const App = () => {
  const [activeTab, setActiveTab] = useState('inputs');
  const [data, setData] = useState([]); // To store fetched data
  const [inputsState, setInputsState] = useState({
    domains: '',
    includeTitles: '',
    excludeTitles: '',
    seniorities: [], // Initialize seniorities to avoid undefined issues
  });
  const [filterUpdated, setFilterUpdated] = useState(false); // To trigger table refresh

  // Handle data fetched from Inputs component
  const handleDataFetched = (fetchedData) => {
    if (Array.isArray(fetchedData)) {
      setData(fetchedData);
      setActiveTab('outputs');
    } else {
      console.error('Invalid data format:', fetchedData);
      alert('Failed to load data. Please try again.');
    }
  };

  // Update Inputs state
  const handleInputsStateChange = (newState) => {
    setInputsState((prevState) => ({ ...prevState, ...newState }));
  };

  // Trigger Outputs filter refresh
  const handleFilterUpdate = () => {
    setFilterUpdated((prev) => !prev); // Toggle state to signal filter update
  };

  // Error boundary for Outputs component
  const renderOutputs = () => {
    try {
      return (
        <Outputs
          data={data}
          excludeTitles={inputsState.excludeTitles}
          filterUpdated={filterUpdated}
        />
      );
    } catch (error) {
      console.error('Error rendering Outputs:', error);
      return <p>Failed to load Outputs. Please try again later.</p>;
    }
  };

  return (
    <div className={styles.container}>
      {/* Tab navigation */}
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'inputs' ? styles.active : ''}`}
          onClick={() => setActiveTab('inputs')}
        >
          Inputs
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'outputs' ? styles.active : ''}`}
          onClick={() => setActiveTab('outputs')}
          disabled={activeTab === 'outputs' && !data.length} // Allow tab switch, but indicate when no data is present
        >
          Outputs
        </button>
      </div>
      {/* Tab content */}
      <div className={styles.tabContent}>
        {activeTab === 'inputs' && (
          <Inputs
            onDataFetched={handleDataFetched}
            inputsState={inputsState}
            onInputsStateChange={handleInputsStateChange}
            onFilterUpdate={handleFilterUpdate}
          />
        )}
        {activeTab === 'outputs' && renderOutputs()}
      </div>
    </div>
  );
};

export default App;